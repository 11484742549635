





























































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";
import SelectFilter from "@/components/common/SelectFilter.vue";
import {
  getRealNum,
  getYears,
  callPlan,
  callLieferantentop5,
  callMaterialdetail,
  callPrevMaterialdetail,
  callKunden,
  callPrevKunden,
  clearCallFlags,
} from "../../helper/utils";
import {
  hideLieferantenCharts,
  showLieferantenCharts,
} from "../../helper/charts";
import { setClientStorage, getClientStorage } from "@/helper/utils";

import lodash from "lodash";

@Component({ components: { SelectFilter } })
export default class TopBar extends Vue {
  @Prop() showAnsicht!: boolean;
  @Prop() showVergleich!: boolean;
  @Prop() showUmsatz!: boolean;
  @Prop() showKunde!: boolean;
  @Prop() showLieferanten!: boolean;
  @Prop() size!: string;
  @Prop({ default: false }) plan!: boolean;
  @Prop({ default: false }) liqPlan!: boolean;
  @Prop() hideYear!: boolean;

  umsatz_low = "";
  umsatz_high = "";
  lieferantenFilters: any = [];
  filterKunden: Array<any> | null = null;
  filterLiefe: Array<any> | null = null;
  planYears: any = [];
  topBarCLients: any = [];
  dropdownSearch: string = "";
  timer: any = null;
  cachedClients: any = [];

  async mounted() {
    getYears();
    // save router to local storage for changing filters purposes
    localStorage.setItem("routeName", JSON.stringify(this.$route.name));
    localStorage.setItem("routeParams", JSON.stringify(this.$route.params));

    await this.getMandators();

    this.planYears = [];
    for (let i = 2015; i <= 2030; i++) {
      this.planYears.push(i);
    }

    let customerId;
    try {
      if (localStorage.getItem("cur_umsatzdetails_kunden")) {
        let localData: any = localStorage.getItem("cur_umsatzdetails_kunden");
        let data = JSON.parse(localData);
        let preselected = this.kunden.find((z: any) => {
          let code = z.label.split(" ");
          return code[0] === data[0];
        });
        if (preselected) {
          this.filterKunden = [preselected];
          store.dispatch("updateFilterKunden", this.filterKunden);
          this.onKundeChange();
        }

        // clear storage
        localStorage.setItem("cur_umsatzdetails_kunden", "");
      }
    } catch (e) {
      localStorage.setItem("cur_umsatzdetails_kunden", "");
    }

    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.clearOnEsc();
      }
    });

    if (getClientStorage()) {
      store.dispatch("updateSelectedClient", getClientStorage());
    }
  }

  get planSelected() {
    return store.getters.planSelected;
  }

  get planYear() {
    return store.getters.planYear;
  }

  get year() {
    return store.getters.selectedYear;
  }

  get rangeStep() {
    return store.getters.rangeStep;
  }

  get client() {
    return store.getters.selectedClient;
  }

  get clients() {
    return store.getters.clients;
  }

  get years() {
    return store.getters.years;
  }

  get kunden() {
    let items: any = [];
    if (
      store.getters.currentCalcs &&
      store.getters.currentCalcs.KUNDENLISTE_ACCPREFIX
    ) {
      store.getters.currentCalcs.KUNDENLISTE_ACCPREFIX.forEach((it: any) => {
        items.push({
          label: it[2] + (it[3] ? " [" : "") + it[3] + (it[3] ? "]" : ""),
          value: it,
        });
      });
    }
    return items;
  }

  get lieferanten() {
    let items: any = [];
    if (
      store.getters.currentSuppliers &&
      store.getters.currentSuppliers.suppliers
    ) {
      store.getters.currentSuppliers.suppliers.forEach((it: any) => {
        //remove id from the name
        const selectLabel = it[2].substr(it[2].indexOf(" ") + 1);
        items.push({
          label:
            selectLabel +
            (it[3] ? (it[3] ? " [" : "") + it[3] + (it[3] ? "]" : "") : ""),
          value: it,
        });
      });
    }
    return items;
  }

  get kundenSelected() {
    return false;
  }

  get prevCalcs() {
    return store.getters.previousCalcs;
  }

  get currentCalcs() {
    return store.getters.currentCalcs;
  }

  get biAgency() {
    return store.getters.selectedBiAgency;
  }

  @Watch("clients")
  onClientsChange() {
    if (this.cachedClients && this.cachedClients.length > 0) {
      this.topBarCLients = this.cachedClients.filter((itm: any) => {
        if (itm[0] == this.biAgency) return itm;
      });
    }
  }

  topBarSearch() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.topBarCLients = this.cachedClients.filter((itm: any) => {
        if (itm[0] == this.biAgency) {
          if (
            this.dropdownSearch == "" ||
            itm[1].toLowerCase().includes(this.dropdownSearch.toLowerCase()) ||
            itm[2].toLowerCase().includes(this.dropdownSearch.toLowerCase())
          ) {
            return itm;
          }
        }
      });
    }, 300);
  }
  @Watch("biAgency")
  onBiAgencyChange() {
    if (this.cachedClients && this.cachedClients.length > 0) {
      // this.topBarCLients = this.cachedClients.filter((itm:any) => itm[0] == this.biAgency)
      this.topBarCLients = this.cachedClients.filter((itm: any) => {
        if (itm[0] == this.biAgency) return itm;
      });
      // this.setMandator(this.topBarCLients[0])
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  async onUrlChange(newVal: any) {
    if (this.cachedClients && this.cachedClients.length > 0) {
      // this.topBarCLients = this.cachedClients.filter((itm:any) => itm[0] == this.biAgency)
      this.topBarCLients = this.cachedClients.filter((itm: any) => {
        if (itm[0] == this.biAgency) return itm;
      });
    }
  }

  preventDefault(e: any) {
    e.stopPropagation();
  }

  clearFilterKunden() {
    // clear filter kunden if being used
    if (this.showKunde) {
      this.filterKunden = null;
      store.dispatch("updateFilterKunden", this.filterKunden);
    }
  }
  clearOnEsc() {
    this.dropdownSearch = "";
    //A way to handle Jquery component inside Vuejs
    this.topBarCLients = this.cachedClients;
    const _bodySelector = document.querySelector("body");
    if (_bodySelector) {
      _bodySelector.click();
    }
  }

  setMandator(client: any) {
    localStorage.setItem("client", JSON.stringify(client));
    // getClientStorage(client)
    setClientStorage(client);
    clearCallFlags();
    store.dispatch("updateSelectedClient", client);
    getYears();
    // when changing mandator, clear filter kunden
    this.clearFilterKunden();
  }

  setYear(year: any) {
    localStorage.setItem("year", JSON.stringify(year));
    clearCallFlags();
    store.dispatch("updateSelectedYear", year);
    store.dispatch("updatePlanYear", year[0]);

    // when changing year, clear filter kunden
    this.clearFilterKunden();
  }

  onKundeChange() {
    let ids: any = [];
    if (this.filterKunden) {
      this.filterKunden.forEach((v: any) => {
        ids.push(v.value[0]);
      });
    }
    let params: any = {};
    if (ids.length > 0) {
      params["filter"] = ids;
    }
    callKunden(params);
    callPrevKunden(params);
  }

  onLieferantenChange() {
    let ids: any = [];
    if (this.filterLiefe) {
      this.filterLiefe.forEach((v: any) => {
        ids.push(v.value[0]);
      });
    }
    this.lieferantenFilters = ids;
    this.filterLieferanten();
  }

  filterLieferanten() {
    let params: any = {};
    if (this.lieferantenFilters.length > 0) {
      params["filter"] = this.lieferantenFilters;
      hideLieferantenCharts();
    }
    if (this.umsatz_low) {
      params["umsatz_low"] = getRealNum(this.umsatz_low);
      hideLieferantenCharts();
    }
    if (this.umsatz_high) {
      params["umsatz_high"] = getRealNum(this.umsatz_high);
      hideLieferantenCharts();
    }
    if (
      this.lieferantenFilters.length == 0 &&
      !this.umsatz_low &&
      !this.umsatz_high
    ) {
      showLieferantenCharts();
    }
    callLieferantentop5(params);
    callMaterialdetail(params);
    callPrevMaterialdetail(params);
  }

  resetLieferanten() {
    this.umsatz_low = "";
    this.umsatz_high = "";
    this.filterLiefe = null;
    this.lieferantenFilters = [];
    this.filterLieferanten();
  }

  changeRange(e: any) {
    store.dispatch("updateRangeStep", e.target.value);
    if (this.showKunde) {
      store.dispatch("updateFilterKunden", this.filterKunden);
    }
  }

  loadFromPlan() {
    if (store.getters.isPlanSaved) {
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      store.dispatch("updatePlanSelected", true);
      api
        .getPlanData(this.client, this.year)
        .then((res: any) => {
          store.dispatch("updateLoading", store.state.loading - 1);
          let prevData = null;
          if (this.prevCalcs) {
            prevData = lodash.cloneDeep(this.prevCalcs);
          } else {
            prevData = { BWA: lodash.cloneDeep(this.currentCalcs["BWA"]) };
          }
          if (res && res.data) {
            if (prevData) {
              prevData["BWA"] = res.data.plan_month;
            }
          } else {
            if (prevData) {
              const l = prevData["BWA"]["legend"];
              for (var i = 0; i < l.length; i++) {
                prevData["BWA"]["total"][l[i][0]] = 0;
                for (var month = 1; month < 13; month++) {
                  prevData["BWA"][month][l[i][0]] = 0;
                }
              }
            }
          }
          store.dispatch("updatePreviousCalcs", prevData);
        })
        .catch((err) => {
          store.dispatch("updatePreviousCalcs", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  loadFromReal() {
    store.dispatch("updatePlanSelected", false);
    if (this.year[0] === this.years[0][0]) {
      store.dispatch("updatePreviousCalcs", null);
    } else {
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .getCalcData(this.client, [this.year[0] - 1])
        .then((res: any) => {
          store.dispatch("updateLoading", store.state.loading - 1);
          store.dispatch("updatePreviousCalcs", res.data);
        })
        .catch((err) => {
          store.dispatch("updatePreviousCalcs", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  setPlanYear(year: any) {
    store.dispatch("updatePlanYear", year);
    localStorage.setItem("planYear", year);

    if (store.getters.isPlanSaved && !this.liqPlan) {
      /* if (
        year >= this.years[0][0] &&
        year <= this.years[this.years.length - 1][0]
      ) {
        let selectedYear: any;
        this.years.forEach((y: any) => {
          if (y[0] == year) {
            selectedYear = y;
          }
        });
        localStorage.setItem("year", JSON.stringify(selectedYear));
        store.dispatch("updateSelectedYear", selectedYear);
      } */
      if (!this.liqPlan) callPlan(year);
    }
  }

  async getMandators() {
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    await api
      .getClients()
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        store.dispatch("updateClients", res.data);
        this.cachedClients = res.data;
      })
      .catch((err) => {
        store.dispatch("updateClients", null);
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }
}
