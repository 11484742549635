








































import { Component, Prop, Vue } from "vue-property-decorator";
import ClickOutside from "vue-click-outside";
import { MultiTitle } from "../../consts";

@Component({ directives: { ClickOutside } })
export default class SelectFilter extends Vue {
  @Prop({ default: "" }) public title!: string;
  @Prop({ default: null }) public value!: any;
  @Prop({ default: [] }) public options!: Array<any>;
  @Prop({ default: "label" }) public filterProp!: string;
  @Prop({ default: false }) public multiselect!: boolean;
  @Prop({ default: false }) public search!: string;
  @Prop({ default: null }) public width!: number;

  public searchText: string = "";
  public isOpen: boolean = false;

  get selectedValue() {
    if (this.value == null) {
      return null;
    }

    if (this.multiselect) {
      let selectedArray = this.value.map((z: any) => {
        if (typeof z === "object") {
          return z[this.filterProp];
        } else {
          return z;
        }
      });
      if (selectedArray.length > 1) {
        return MultiTitle;
      } else {
        return selectedArray[0];
      }
    } else {
      if (typeof this.value === "object") {
        return this.value[this.filterProp];
      } else {
        return this.value;
      }
    }
  }

  get filterOptions() {
    let filterOptions = [...this.options];

    // sort filter options (first is selected)
    filterOptions.sort((a: any, b: any) => {
      if (this.multiselect && this.value) {
        let hasA = this.value.includes(a);
        let hasB = this.value.includes(b);
        return hasA && hasB ? 0 : hasA ? -1 : hasB ? 1 : 0;
      }
      return 0;
    });

    if (this.searchText) {
      return filterOptions.filter((z) => {
        if (typeof z === "object") {
          return String(z[this.filterProp])
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        } else if (typeof z === "number") {
          return `${z}`.includes(this.searchText);
        } else {
          return z.toLowerCase().includes(this.searchText.toLowerCase());
        }
      });
    }
    return filterOptions;
  }

  outsideClick(): void {
    this.isOpen = false;
  }

  toogleOpen(val?: boolean): void {
    this.isOpen = val === undefined ? !this.isOpen : val;
  }

  selectItem(value: any, isActive: any) {
    if (!this.multiselect) {
      if (!isActive) {
        this.$emit("input", value);
        this.$emit("change", value);
        this.toogleOpen();
      }
    } else {
      let newValue;
      if (isActive) {
        newValue = this.value.filter((z: any) => z !== value);
      } else {
        newValue = this.value ? [...this.value, value] : [value];
      }
      this.$emit("input", newValue);
      this.$emit("change", newValue);
    }
  }

  clearall(): void {
    this.$emit("input", null);
    this.$emit("change", null);
  }

  isActiveItem(item: any) {
    if (this.multiselect) {
      return this.value && this.value.includes(item);
    } else {
      return this.value && this.value === item;
    }
  }
}
