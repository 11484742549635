declare const d3: any;
declare const $: any;
import store from "@/store";
import { getGermanNum } from "./utils";
import { getRouter } from "@/router";

export const smallNoDataHtml =
  '<div class="no-data primary-color">' +
  '<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">' +
  '<g opacity="0.3">' +
  '<path d="M67.4292 40.9732H37.0349V10.579C37.0349 9.35074 36.0523 8.36816 34.8241 8.36816C15.6311 8.36816 0.00820923 23.9911 0.00820923 43.184C0.00820923 62.377 15.6311 77.9999 34.8241 77.9999C54.017 77.9999 69.64 62.377 69.64 43.184C69.64 41.9722 68.641 40.9732 67.4292 40.9732ZM34.8241 73.5783C18.0712 73.5783 4.42979 59.9369 4.42979 43.184C4.42979 27.1681 16.8757 14.0016 32.6133 12.8716V43.184C32.6133 44.4123 33.5959 45.3948 34.8241 45.3948H65.1365C64.0065 61.1324 50.84 73.5783 34.8241 73.5783Z" fill="currentColor"/>' +
  '<path d="M77.9918 34.7012C77.959 26.2511 74.8639 18.1121 69.2796 11.7745C62.6636 4.29057 53.1654 0 43.1759 0C41.9477 0 40.9651 0.982574 40.9651 2.21079V34.8159C40.9651 36.0441 41.9477 37.0267 43.1759 37.0267H75.781C77.0092 37.0267 77.9918 36.0441 77.9918 34.8159C77.9918 34.7831 77.9918 34.7504 77.9918 34.7012ZM45.3867 32.6215V4.51984C53.28 5.09301 60.6821 8.74491 65.9553 14.7222C70.3768 19.717 72.997 26.0055 73.4883 32.6215H45.3867Z" fill="currentColor"/>' +
  "</g>" +
  "</svg>" +
  '<div class="my-2">Keine Daten verfügbar</div>' +
  "<div>Zu dieser Kategorie sind zur Zeit keine Daten verfügbar</div>" +
  "</div>";

export function getQuarter(month: any) {
  return Math.floor(month / 3) + 1;
}

export function combineMonthToQuarter(d: any) {
  var q: any = {};
  for (var item in d) {
    var date: any = "";
    var key = "";
    if (item.charAt(4) == "-") {
      date = item.split("-");
      key = date[0] + "-Q" + getQuarter(Number(date[1]) - 1);
    } else if (item.charAt(2) == ".") {
      date = item.split(".");
      key = "20" + date[1] + "-Q" + getQuarter(Number(date[0]) - 1);
    }

    if (item.charAt(4) == "-" || item.charAt(2) == ".") {
      if (q.hasOwnProperty(key)) {
        var raw = d[item];
        if (Array.isArray(raw)) {
          for (var i = 0; i < raw.length; i++) {
            var j = raw[i][0];
            var z = q[key].findIndex(function(v: any) {
              return v[0] === j;
            });

            if (z === -1) {
              q[key].push(raw[i][2]);
            } else {
              q[key][z][2] = q[key][z][2] + raw[i][2];
            }
          }
        } else {
          q[key] = q[key] + raw;
        }
      } else {
        q[key] = d[item];
      }
    }
  }
  return q;
}

export function changeDateFormat(d: any) {
  var d2: any = {};
  if (!d) {
    return d2;
  }
  var keys = Object.keys(d);
  for (var i = 0; i < keys.length; i++) {
    if (keys[i].charAt(4) == "-") {
      var date = keys[i].split("-");
      var newKey = date[1] + "." + date[0].toString().substr(2);
      d2[newKey] = d[keys[i]];
    } else {
      d2[keys[i]] = d[keys[i]];
    }
  }
  return d2;
}

export function getMonthText(text: any) {
  var months = [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez",
  ];
  if (text.indexOf(".") > 0) {
    return months[parseInt(text.substring(0, text.indexOf("."))) - 1];
  } else if (text.indexOf("-") > 0 && text.indexOf("Q") < 0) {
    return months[
      parseInt(text.substring(text.indexOf("-") + 1, text.length)) - 1
    ];
  }
  return text;
}

export function topRoundedRect(
  x: any,
  y: any,
  width: any,
  height: any,
  radius: any
) {
  return (
    "M" +
    (x - 3) +
    "," +
    y +
    "h" +
    width +
    "a" +
    radius +
    "," +
    radius +
    " 0 0 1 " +
    radius +
    "," +
    radius +
    "v" +
    (height - radius) +
    "h" +
    -width +
    "v" +
    (radius - height) +
    "a" +
    radius +
    "," +
    radius +
    " 0 0 1 " +
    radius +
    "," +
    -radius +
    "z"
  );
}

export function chartVerticalStackBar(d: any, id: any) {
  if (!d3.selectAll("#" + id).node()) {
    return;
  }
  d3.selectAll("#" + id + " svg").remove();
  var svg = d3
    .select("#" + id)
    .append("svg")
    .attr("class", "vertical-stack-bar")
    .attr(
      "width",
      (d3.selectAll("#" + id).node() as any).getBoundingClientRect().width
    )
    .attr(
      "height",
      (d3.selectAll("#" + id).node() as any).getBoundingClientRect().height
    );
  var margin = { top: 20, right: 20, bottom: 20, left: 45 };
  var width =
    (svg.node() as any).getBoundingClientRect().width -
    margin.left -
    margin.right;
  var height =
    (svg.node() as any).getBoundingClientRect().height -
    margin.top -
    margin.bottom;
  var g = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
  var x = <any>d3
    .scaleBand()
    .rangeRound([0, width])
    .paddingInner(0.7)
    .align(0.1);
  var y = d3.scaleLinear().rangeRound([height, 0]);
  var colors = [
    "var(--primary)",
    "var(--primary-lighter-20)",
    "var(--primary-lighter-40)",
    "var(--primary-lighter-60)",
    "var(--primary-lighter-80)",
  ];
  var data = [];
  var maxVal = 0;
  var keys = Object.keys(d);
  for (var i = 0; i < keys.length; i++) {
    if (d[keys[i]] && typeof d[keys[i]] === "object" && keys[i] !== "total") {
      var total = 0;
      for (var j = 0; j < d[keys[i]].length; j++) {
        if (d[keys[i]][j][2] < 0) {
          d[keys[i]][j][2] = 0;
        }
        if (d[keys[i]][j][2]) {
          total += d[keys[i]][j][2];
        }
        if (typeof d[keys[i]][j] === "object") {
          d[keys[i]][j][3] = getMonthText(keys[i]);
          d[keys[i]][j][4] = j;
          d[keys[i]][j][5] = total;
        }
      }
      if (total > maxVal) {
        maxVal = total;
      }
      data.push({ month: getMonthText(keys[i]), data: d[keys[i]] });
    }
  }

  x.domain(
    data.map(function(d) {
      return d.month;
    })
  );
  y.domain([0, maxVal]).nice();

  var rectG = g
    .append("g")
    .selectAll("g")
    .data(data)
    .enter();
  g.append("g")
    .attr("class", "axis axis-y")
    .attr("transform", "translate(-10, 0)")
    .call(
      d3
        .axisLeft(y)
        .ticks(5, "s")
        .tickSize(-width)
    );
  rectG
    .append("g")
    .selectAll("path")
    .data(function(d: any) {
      return d.data.sort(function(a: any, b: any) {
        if (a[5] > b[5]) {
          return -1;
        } else if (a[5] < b[5]) {
          return 1;
        }
        return 0;
      });
    })
    .enter()
    .append("path")
    .attr("d", function(d: any) {
      return d[2]
        ? topRoundedRect(
            x(d[3]),
            y(d[5]),
            x.bandwidth(),
            height > 0 ? height : 0,
            4
          )
        : null;
    })
    .attr("fill", function(d: any) {
      return colors[d[4]];
    });

  var tip = d3
    .tip()
    .attr("class", "d3-tip bg-white shadow rounded px-3 py-2")
    .offset([-20, 0])
    .html(function(d: any) {
      var html = "";
      for (var i = 0; i < d.data.length; i++) {
        if (d.data[i][2]) {
          html +=
            '<div class="d-flex align-items-center my-3 justify-content-between">' +
            '<div class="d-flex align-items-center">' +
            '<span class="tooltip-legend-item mr-3" style="background:' +
            colors[d.data[i][4]] +
            '" class="mr-2"></span>' +
            '<span class="flex-1">' +
            d.data[i][1] +
            "</span>" +
            "</div>" +
            '<span class="ml-3">' +
            (d.data[i][2] / 1000).toFixed() +
            "k</span>" +
            "</div>";
        }
      }
      return html;
    });

  rectG
    .append("path")
    .attr("d", function(d: any) {
      return d.data.length > 0 && y(d.data[0][5])
        ? topRoundedRect(x(d.month), y(d.data[0][5]), x.bandwidth(), height, 4)
        : "";
    })
    .attr("fill", "transparent")
    .on("mouseover", tip.show)
    .on("mouseout", tip.hide);
  svg.call(tip);

  g.append("rect")
    .attr("x", -3)
    .attr("y", height)
    .attr("width", width > 0 ? width + 10 : 0)
    .attr("height", margin.bottom)
    .attr("fill", "#ffffff");
  rectG
    .append("text")
    .text(function(d: any) {
      var total = 0;
      for (var i = 0; i < d.data.length; i++) {
        if (d.data[i][2]) {
          total += d.data[i][2];
        }
      }
      return (total / 1000).toFixed() + "k";
    })
    .attr("x", function(d: any) {
      return x(d.month) + x.bandwidth() / 2;
    })
    .attr("y", function(d: any) {
      var total = 0;
      for (var i = 0; i < d.data.length; i++) {
        if (d.data[i][2]) {
          total += d.data[i][2];
        }
      }
      return y(total) - 5;
    })
    .attr("text-anchor", "middle")
    .attr("fill", "var(--primary-dark)")
    .attr("stroke-width", 0);

  g.append("g")
    .attr("class", "axis axis-x")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x));
}

export function drawVerticalStackBarByPeriod(data: any, id: any) {
  let chartData =
    store.getters.rangeStep === "quarterly"
      ? combineMonthToQuarter(data)
      : changeDateFormat(data);
  chartVerticalStackBar(chartData, id);
}

export function composeData(data: any, key: any) {
  if (!data) {
    return [];
  }
  if (Object.keys(data).length === 0) {
    return [];
  }
  var result: any = [];
  for (var i = 1; i < 13; i++) {
    var k =
      (i < 10 ? "0" + i : i) +
      "." +
      (store.getters.selectedYear && store.getters.selectedYear[0])
        .toString()
        .substr(2);
    result[k] = data[i][key];
  }
  if (store.getters.rangeStep === "quarterly") {
    result = combineMonthToQuarter(result);
  }
  return result;
}

export function chartVerticalGroupBar(
  d0: any,
  d1: any,
  colt1: any,
  colt2: any,
  id: any
) {
  if (!d3.selectAll("#" + id).node()) {
    return;
  }
  d3.select("." + id + ".vertical-legend1 span").html(colt1);
  d3.select("." + id + ".vertical-legend2 span").html(colt2);
  d3.selectAll("#" + id + " svg").remove();
  var svg = d3
    .select("#" + id)
    .append("svg")
    .attr("class", "vertical-group-bar")
    .attr(
      "width",
      (d3.selectAll("#" + id).node() as any).getBoundingClientRect().width
    )
    .attr(
      "height",
      (d3.selectAll("#" + id).node() as any).getBoundingClientRect().height
    );
  var margin = { top: 20, right: 20, bottom: 35, left: 45 };
  var width =
    (svg.node() as any).getBoundingClientRect().width -
    margin.left -
    margin.right;
  var height =
    (svg.node() as any).getBoundingClientRect().height -
    margin.top -
    margin.bottom;
  const color = ["var(--primary-dark)", "var(--primary-light)"];

  const plot = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  const x0 = d3
    .scaleBand()
    .rangeRound([0, width])
    .paddingInner(0.25);

  const x1: any = d3.scaleBand().padding(0.15);

  const y = d3
    .scaleLinear()
    .range([height, 0])
    .nice();

  const z: any = d3
    .scaleOrdinal()
    .range(["var(--primary)", "var(--primary-light)"]);
  var data: any = [];
  var minVal = 0;
  var maxVal = 0;
  var keys = [colt1, colt2];

  var d0keys = Object.keys(d0);
  for (var i = 0; i < d0keys.length; i++) {
    var object: any = {};
    object[colt1] = d0[d0keys[i]];
    object[colt2] = d1[d0keys[i]] | 0;
    object.id = getMonthText(d0keys[i]);
    data.push(object);
    if (d0[d0keys[i]] > maxVal) {
      maxVal = d0[d0keys[i]];
    }
    if (d1[d0keys[i]] > maxVal) {
      maxVal = d1[d0keys[i]];
    }
    if (d0[d0keys[i]] < minVal) {
      minVal = d0[d0keys[i]];
    }
    if (d1[d0keys[i]] < minVal) {
      minVal = d1[d0keys[i]];
    }
  }

  x0.domain(
    data.map(function(d: any) {
      return d.id;
    })
  );
  x1.domain(keys).rangeRound([0, x0.bandwidth()]);
  y.domain([minVal, maxVal]).nice();

  plot
    .append("g")
    .attr("class", "axis axis-x")
    .attr("transform", "translate(0," + (height + 10) + ")")
    .call(d3.axisBottom(x0));
  plot
    .append("g")
    .attr("class", "axis axis-y")
    .attr("transform", "translate(-10, 0)")
    .call(
      d3
        .axisLeft(y)
        .ticks(5, "s")
        .tickSize(-width)
    );

  drawBar();

  function drawBar() {
    var rectG = plot
      .insert("g", "#zero_line")
      .attr("id", "bar")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function(d: any) {
        return "translate(" + x0(d.id) + ",0)";
      })
      .selectAll("rect")
      .data(function(d: any) {
        return keys.map(function(key) {
          return { key: key, value: d[key], id: d.id };
        });
      })
      .enter();
    rectG
      .append("path")
      .attr("d", function(d: any) {
        if (y(d.value) - y(0) < 0) {
          return topRoundedRect(
            x1(d.key),
            y(Math.max(0, d.value)),
            x1.bandwidth(),
            Math.abs(y(d.value) - y(0)),
            4
          );
        } else if (y(d.value) - y(0) > 0) {
          return bottomRoundedRect(
            x1(d.key),
            y(Math.max(0, d.value)),
            x1.bandwidth(),
            Math.abs(y(d.value) - y(0)),
            4
          );
        }
        return null;
      })
      .attr("fill", function(d: any) {
        return z(d.key);
      });
    rectG
      .append("text")
      .text(function(d: any) {
        return d.value === 0 ? "" : (d.value / 1000).toFixed() + "k";
      })
      .attr("x", function(d: any) {
        return x1(d.key) + x1.bandwidth() / 2;
      })
      .attr("y", function(d: any) {
        if (d.value > 0) {
          return y(Math.max(0, d.value)) - 5;
        }
        return y(Math.max(0, d.value)) + Math.abs(y(d.value) - y(0)) + 15;
      })
      .attr("text-anchor", "middle")
      .attr("fill", "var(--primary-dark)");
  }
  return;
}

export function bottomRoundedRect(
  x: any,
  y: any,
  width: any,
  height: any,
  radius: any
) {
  return (
    "M" +
    (x - 3) +
    "," +
    y +
    "h" +
    (width + radius) +
    "v" +
    (height - radius) +
    "a" +
    radius +
    "," +
    radius +
    " 0 0 1 " +
    -radius +
    "," +
    radius +
    "h" +
    (2 * radius - width) +
    "a" +
    radius +
    "," +
    radius +
    " 0 0 1 " +
    -radius +
    "," +
    -radius +
    "v" +
    (radius - height) +
    "z"
  );
}

export function chartHorizontalBar(d0: any, d1: any, id: any, maxVal: any) {
  if (!d3.selectAll("#" + id).node()) {
    return;
  }
  var width = (d3.select("#" + id).node() as any).getBoundingClientRect().width;
  if (d0 && d0[0]) {
    d3.select("#" + id)
      .append("path")
      .attr("d", function(d: any) {
        return d0[0][1]
          ? rightRoundedRect(0, 0, (d0[0][1] * width) / maxVal, 14, 4)
          : "";
      })
      .attr("fill", "var(--primary)");
  }
  if (d1 && d1[0]) {
    d3.select("#" + id)
      .append("path")
      .attr("d", function(d: any) {
        return d1[0][1]
          ? rightRoundedRect(0, 18, (d1[0][1] * width) / maxVal, 14, 4)
          : "";
      })
      .attr("fill", "var(--primary-light)");
  }
  return;
}

export function rightRoundedRect(
  x: any,
  y: any,
  width: any,
  height: any,
  radius: any
) {
  return (
    "M" +
    x +
    "," +
    y +
    "h" +
    (width - radius) +
    "a" +
    radius +
    "," +
    radius +
    " 0 0 1 " +
    radius +
    "," +
    radius +
    "v" +
    (height - 2 * radius) +
    "a" +
    radius +
    "," +
    radius +
    " 0 0 1 " +
    -radius +
    "," +
    radius +
    "h" +
    (radius - width) +
    "z"
  );
}

export function chartVerticalSingleBar(
  d0: any,
  labels: any,
  colt: any,
  id: any,
  changeBarWidth: any,
  useLabel: any,
  colors: any,
  hideXAxis: any
) {
  if (!d3.selectAll("#" + id).node()) {
    return;
  }
  if (typeof changeBarWidth === "undefined" || changeBarWidth === null) {
    changeBarWidth = false;
  }
  d3.selectAll("#" + id + " svg").remove();
  var svg = d3
    .selectAll("#" + id)
    .append("svg")
    .attr("class", "vertical-small-bar")
    .attr(
      "width",
      (d3.selectAll("#" + id).node() as any).getBoundingClientRect().width
    )
    .attr(
      "height",
      (d3.selectAll("#" + id).node() as any).getBoundingClientRect().height
    );
  var margin = { top: 20, right: 15, bottom: 30, left: 45 };
  var data = [];
  var maxVal = 0;
  var minVal = 0;
  if (hideXAxis) {
    margin.top = 15;
    margin.bottom = 15;
  }
  if (d0.length > 4 && id != "chart_uk_jahre") {
    d0 = d0.slice(1, d0.length);
    labels = labels.slice(1, labels.length);
  }
  if (changeBarWidth && !useLabel) {
    if (store.getters.years && store.getters.selectedYear) {
      for (
        var j = store.getters.years[0][0];
        j <= store.getters.selectedYear[0];
        j++
      ) {
        if (
          (id != "chart_uk_jahre" && j < store.getters.selectedYear[0]) ||
          id == "chart_uk_jahre"
        ) {
          labels[j - store.getters.years[0][0]] = j;
          d0[j - store.getters.years[0][0]] =
            d0[j - store.getters.years[0][0]] || 0;
        }
      }
    }
  }
  for (var i: any = 0; i < d0.length; i++) {
    data.push({
      year: labels[i],
      val: d0[i],
      color: colors ? colors[i] : null,
    });
    if (d0[i] > maxVal) {
      maxVal = d0[i];
    }
    if (d0[i] < minVal) {
      minVal = d0[i];
    }
  }
  var width =
    (svg.node() as any).getBoundingClientRect().width -
    margin.left -
    margin.right;
  var height =
    (svg.node() as any).getBoundingClientRect().height -
    margin.top -
    margin.bottom;
  var g = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
  var x: any = d3
    .scaleBand()
    .rangeRound([0, width])
    .paddingInner(d0.length == 1 ? 0 : changeBarWidth ? 0.3 : 0.6)
    .align(0.1);
  var y = d3.scaleLinear().rangeRound([height, 0]);
  var maxBarWidth = 100;

  var tip = d3
    .tip()
    .attr("class", hideXAxis ? "d3-tip bg-white shadow rounded px-3 py-2" : "")
    .offset([-20, -120])
    .html(function() {
      var html = "";
      if (hideXAxis) {
        for (var i = 0; i < d0.length; i++) {
          html +=
            '<div class="d-flex align-items-center my-3 justify-content-between">' +
            '<div class="d-flex align-items-center">' +
            '<span class="tooltip-legend-item mr-3" style="background:' +
            colors[i] +
            '" class="mr-2"></span>' +
            '<span class="flex-1">' +
            labels[i] +
            "</span>" +
            "</div>" +
            '<span class="ml-3">' +
            (d0[i] / 1000).toFixed() +
            "k</span>" +
            "</div>";
        }
      }
      return html;
    });
  x.domain(
    data.map(function(d) {
      return d.year;
    })
  );
  y.domain([minVal, maxVal]).nice();

  g.append("g")
    .attr("class", "axis axis-y")
    .attr("transform", "translate(-10, 0)")
    .call(
      d3
        .axisLeft(y)
        .ticks(2, "s")
        .tickSize(-width)
    );
  var rectG = g
    .append("g")
    .selectAll("g")
    .data(data)
    .enter();
  rectG
    .append("path")
    .attr("d", function(d: any) {
      if (y(d.val) - y(0) < 0) {
        return topRoundedRect(
          x.bandwidth() > maxBarWidth
            ? x(d.year) + (x.bandwidth() - maxBarWidth) / 2
            : x(d.year),
          y(Math.max(0, d.val)),
          x.bandwidth() > maxBarWidth ? maxBarWidth : x.bandwidth(),
          Math.abs(y(d.val) - y(0)),
          4
        );
      } else if (y(d.val) - y(0) > 0) {
        return bottomRoundedRect(
          x.bandwidth() > maxBarWidth
            ? x(d.year) + (x.bandwidth() - maxBarWidth) / 2
            : x(d.year),
          y(Math.max(0, d.val)),
          x.bandwidth() > maxBarWidth ? maxBarWidth : x.bandwidth(),
          Math.abs(y(d.val) - y(0)),
          4
        );
      }
      return null;
    })
    .attr("fill", function(d: any) {
      return d.color || "var(--primary)";
    })
    .on("mouseover", tip.show)
    .on("mouseout", tip.hide);
  rectG
    .append("text")
    .attr("x", function(d: any) {
      return x(d.year) + x.bandwidth() / 2;
    })
    .attr("y", function(d: any) {
      if (d.val >= 0) {
        return y(Math.max(0, d.val)) - 5;
      }
      return y(Math.max(0, d.val)) + Math.abs(y(d.val) - y(0)) + 15;
    })
    .attr("text-anchor", "middle")
    .attr("fill", "var(--primary-dark)")
    .text(function(d: any) {
      if (typeof d.val !== "number") {
        return "";
      }
      return getGermanNum(
        Math.abs(d.val / 1000000) >= 1
          ? (d.val / 1000000).toFixed(1) + "M"
          : (d.val / 1000).toFixed(1) + "k"
      );
    });
  svg.call(tip);

  if (!hideXAxis) {
    g.append("g")
      .attr("class", "axis axis-x")
      .attr("transform", "translate(0," + (height + 10) + ")")
      .call(d3.axisBottom(x));
  }
  return;
}

export function bwaRenderChartUmsatzTop10() {
  const d =
    store.getters.currentCalcs && store.getters.currentCalcs["UMS_TOP10"];
  if (!d3.selectAll("#chart_ums_top10").node()) {
    return;
  }
  $("#chart_ums_top10").html("");
  var data = [];
  if (d && d.length > 0) {
    for (var i = 0; i < d.length; i++) {
      data.push({
        Species: d[i][0],
        Probability: d[i][1],
        Value: Math.abs(d[i][2]),
      });
    }
    $("#chart_ums_top10_left_arrow").removeClass("d-none");
    $("#chart_ums_top10_right_arrow").removeClass("d-none");
    $("#chart_ums_top10_legend")
      .removeClass("d-none")
      .addClass("d-flex");
  } else {
    $("#chart_ums_top10").html(smallNoDataHtml);
    $("#chart_ums_top10_left_arrow").addClass("d-none");
    $("#chart_ums_top10_right_arrow").addClass("d-none");
    $("#chart_ums_top10_legend")
      .removeClass("d-flex")
      .addClass("d-none");
  }

  if (!d) {
    return;
  }

  if (data.length) {
    data.sort(function(a, b) {
      return b.Value - a.Value;
    });
    var size = d3.min([
      450,
      (d3.selectAll("#chart_ums_top10").node() as any).getBoundingClientRect()
        .width,
    ]);
    var donut = donutChart()
      .width(size)
      .height(size)
      .cornerRadius(3)
      .padAngle(0.036)
      .variable("Value")
      .category("Species")
      .selectedId("chart_ums_top10")
      .big(true);

    d3.select("#chart_ums_top10")
      .datum(data)
      .call(donut);
  }
}

export function donutChart() {
  var width: any,
    height: any,
    margin = { top: 10, right: 10, bottom: 10, left: 10 },
    colour = d3.scaleOrdinal(d3.schemeCategory10),
    variable: any,
    category: any,
    padAngle: any,
    radius: any,
    floatFormat = d3.format(".4r"),
    cornerRadius: any,
    percentFormat = d3.format(",.2%"),
    selectedId: any,
    selectedIndex = -1,
    big = false,
    small = false;

  let chart: any = function(selection: any) {
    selection.each(function(data: any, key: any) {
      // generate chart
      var radius = Math.min(width, height) / 2;
      // ===========================================================================================

      // creates a new pie generator
      var pie: any = (d3.pie() as any)
        .value(function(d: any) {
          return floatFormat(d[variable]);
        })
        .sort(null);

      // contructs and arc generator. This will be used for the donut. The difference between outer and inner
      // radius will dictate the thickness of the donut
      var arc = d3
        .arc()
        .outerRadius(radius * 0.8)
        .innerRadius(radius * 0.5)
        .cornerRadius(cornerRadius)
        .padAngle(padAngle);

      // this arc is used for aligning the text labels
      var outerArc = d3
        .arc()
        .outerRadius(radius * 0.7)
        .innerRadius(radius * 0.6);
      // ===========================================================================================

      // ===========================================================================================
      // append the svg object to the selection
      var svg = selection
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
      // ===========================================================================================

      // ===========================================================================================
      // g elements to keep elements within svg modular
      svg.append("g").attr("class", "lines");
      svg.append("g").attr("class", "slices");
      svg.append("g").attr("class", "labelName");

      var temp = [
        {
          Probability: "",
          Species: "",
          Value: 1,
        },
      ];

      var temp_path = svg
        .select(".lines")
        .datum(temp)
        .selectAll("path")
        .data(pie)
        .enter()
        .append("path")
        .attr("fill", "var(--primary-lighter)")
        .attr("d", outerArc);
      // ===========================================================================================

      // ===========================================================================================
      // add and colour the donut slices
      var paths = svg
        .select(".slices")
        .datum(data)
        .selectAll("path")
        .data(pie)
        .enter();
      var path: any = paths
        .append("path")
        .attr("fill", "var(--primary-light)")
        .attr("d", arc)
        .attr("class", function(d: any, i: any) {
          return selectedId + "-" + i;
        })
        .on("mouseover", (d: any, i: any) => handleMouseOver(d, i, path))
        .on("mouseleave", (d: any, i: any) => handleMouseOut(d, i, path));

      var tip = d3
        .tip()
        .attr("class", "d3-tip")
        .direction(function(d: any) {
          if (d.startAngle > 4.7) {
            return "n";
          } else if (d.startAngle > 3.1) {
            return "s";
          } else if (d.startAngle > 1.57) {
            return "s";
          }
          return "n";
        })
        .offset(function(d: any) {
          const radio = width / 500;
          if (d.startAngle > 4.7) {
            return [-150, -200];
          } else if (d.startAngle > 3.1) {
            return [30, -200];
          } else if (d.startAngle > 1.57) {
            return [30, -200];
          }
          return [-150, -200];
        })
        .html(function(d: any) {
          var className = big ? "d-none" : "";
          var content =
            '<div id="donut_tip" class="donut-tip ' +
            className +
            '">' +
            '<div class="donut-tip-title">' +
            d.data.Probability +
            "</div>" +
            '<div class="donut-tip-body">' +
            '<div class="donut-tip-item">' +
            "<span>Betrag</span>" +
            '<span class="donut-tip-value">' +
            abbreviate(d.data.Value, 1, false, false) +
            "</span>" +
            "</div>" +
            "</div>" +
            "</div>";
          return content;
        });

      svg.call(tip);

      var pathAnim = function(path: any, dir: any) {
        switch (dir) {
          case 0:
            path.transition().attr(
              "d",
              d3
                .arc()
                .outerRadius(radius * 0.85)
                .innerRadius(radius * 0.45)
                .cornerRadius(cornerRadius)
                .padAngle(padAngle)
            );
            break;

          case 1:
            path.transition().attr(
              "d",
              d3
                .arc()
                .outerRadius(radius * 0.8)
                .innerRadius(radius * 0.5)
                .cornerRadius(cornerRadius)
                .padAngle(padAngle)
            );
            break;
        }
      };

      // Initial showing the detail
      $("#" + selectedId + "_order").html(toolTipHTML(0));
      $("#" + selectedId + "_type").html(data[0]["Probability"]);
      $("#" + selectedId + "_title").html("Betrag");
      $("#" + selectedId + "_value").html(
        abbreviate(data[0]["Value"], 1, false, false)
      );
      $("#" + selectedId + "_order").removeClass("vibility-hidden");
      $("#" + selectedId + "_type").removeClass("vibility-hidden");
      $("#" + selectedId + "_title").removeClass("vibility-hidden");
      $("#" + selectedId + "_value").removeClass("vibility-hidden");

      // clicking left arrow
      $("#" + selectedId + "_left_arrow").click(function() {
        if (selectedIndex < 1) {
          selectedIndex = data.length - 1;
        } else {
          selectedIndex--;
        }
        arrowClick(selectedId, selectedIndex);
      });
      // clicking right arrow
      $("#" + selectedId + "_right_arrow").click(function() {
        if (selectedIndex === data.length - 1) {
          selectedIndex = 0;
        } else {
          selectedIndex++;
        }
        arrowClick(selectedId, selectedIndex);
      });

      function arrowClick(selectedId: any, selectedIndex: any) {
        clearSelectedPaths();
        pathAnim(d3.select("." + selectedId + "-" + selectedIndex), 0);
        d3.select("." + selectedId + "-" + selectedIndex).style(
          "fill",
          "var(--primary)"
        );
        showInnerTip(selectedIndex);

        $("#" + selectedId + "_order").html(toolTipHTML(selectedIndex));
        $("#" + selectedId + "_type").html(data[selectedIndex]["Probability"]);
        $("#" + selectedId + "_title").html("Betrag");
        $("#" + selectedId + "_value").html(
          abbreviate(data[selectedIndex]["Value"], 1, false, false)
        );
        $("#" + selectedId + "_order").removeClass("vibility-hidden");
        $("#" + selectedId + "_type").removeClass("vibility-hidden");
        $("#" + selectedId + "_title").removeClass("vibility-hidden");
        $("#" + selectedId + "_value").removeClass("vibility-hidden");
      }

      function clearSelectedPaths() {
        for (var i = 0; i < data.length; i++) {
          pathAnim(d3.select("." + selectedId + "-" + i), 1);
          d3.select("." + selectedId + "-" + i).style(
            "fill",
            "var(--primary-light)"
          );
        }
      }

      function showDetail(d: any) {
        $("#" + selectedId + "_order").html(toolTipHTML(d.index));
        $("#" + selectedId + "_type").html(d.data.Probability);
        $("#" + selectedId + "_title").html("Betrag");
        $("#" + selectedId + "_value").html(
          abbreviate(d.data.Value, 1, false, false)
        );
        $("#" + selectedId + "_order").removeClass("vibility-hidden");
        $("#" + selectedId + "_type").removeClass("vibility-hidden");
        $("#" + selectedId + "_title").removeClass("vibility-hidden");
        $("#" + selectedId + "_value").removeClass("vibility-hidden");
      }

      function disableDetail() {
        $("#" + selectedId + "_order").addClass("vibility-hidden");
        $("#" + selectedId + "_type").addClass("vibility-hidden");
        $("#" + selectedId + "_title").addClass("vibility-hidden");
        $("#" + selectedId + "_value").addClass("vibility-hidden");
      }

      function handleMouseOver(d: any, i: any, path: any) {
        if (big) {
          disableInnerTip(true);
        }
        clearSelectedPaths();
        d3.select(path._groups[0][i]).style("fill", "var(--primary)");
        pathAnim(d3.select(path._groups[0][i]), 0);
        tip.show(d);
        showInnerTip(d.index);
        showDetail(d);
        selectedIndex = d.index;
      }

      function handleMouseOut(d: any, i: any, path: any) {
        tip.hide(d);
        if (big) {
          return;
        }
        clearSelectedPaths();
        d3.select(path._groups[0][i]).style("fill", "var(--primary-light)");
        pathAnim(d3.select(path._groups[0][i]), 1);
        disableInnerTip(false);
        disableDetail();
      }
      // ===========================================================================================

      // calculates the angle for the middle of a slice
      function midAngle(d: any) {
        return d.startAngle + (d.endAngle - d.startAngle) / 2;
      }

      // function that creates and adds the tool tip to a selected element
      function showInnerTip(index: any) {
        svg
          .append("circle")
          .attr("class", "toolCircle" + (big ? "" : " small"))
          .attr("r", radius * 0.3)
          .style("fill", "var(--primary)");

        svg
          .append("text")
          .attr("class", "toolCircle" + (big ? "" : " small"))
          .attr("dy", small ? 5 : 10)
          .text(toolTipHTML(index))
          .style("font-size", ajustFontSize())
          .style("font-weight", "bold")
          .style("text-anchor", "middle")
          .style("fill", "#ffffff");
      }

      function disableInnerTip(all: any) {
        var cl = ".toolCircle";
        if (!all) {
          cl += ".small";
        }
        svg.selectAll(cl).remove();
      }

      function ajustFontSize() {
        if (Math.min(width, height) > 450) {
          return "3em";
        } else if (
          Math.min(width, height) <= 450 &&
          Math.min(width, height) > 200
        ) {
          return "2em";
        }
        return "14px";
      }

      // function to create the HTML string for the tool tip. Loops through each key in data object
      // and returns the html string key: value
      function toolTipHTML(i: any) {
        var index = i + 1;
        var tip = "";
        if (index < 10) {
          tip = "0" + index.toString();
        } else {
          tip = index.toString();
        }
        return tip;
      }
      // ===========================================================================================
      if (key === 0 && big) {
        arrowClick(selectedId, 0);
      }
    });
  };

  chart.width = function(value: any) {
    if (!arguments.length) return width;
    width = value;
    return chart;
  };

  chart.height = function(value: any) {
    if (!arguments.length) return height;
    height = value;
    return chart;
  };

  chart.margin = function(value: any) {
    if (!arguments.length) return margin;
    margin = value;
    return chart;
  };

  chart.radius = function(value: any) {
    if (!arguments.length) return radius;
    radius = value;
    return chart;
  };

  chart.padAngle = function(value: any) {
    if (!arguments.length) return padAngle;
    padAngle = value;
    return chart;
  };

  chart.cornerRadius = function(value: any) {
    if (!arguments.length) return cornerRadius;
    cornerRadius = value;
    return chart;
  };

  chart.colour = function(value: any) {
    if (!arguments.length) return colour;
    colour = value;
    return chart;
  };

  chart.variable = function(value: any) {
    if (!arguments.length) return variable;
    variable = value;
    return chart;
  };

  chart.category = function(value: any) {
    if (!arguments.length) return category;
    category = value;
    return chart;
  };

  chart.selectedId = function(value: any) {
    if (!arguments.length) return selectedId;
    selectedId = value;
    return chart;
  };

  chart.big = function(value: any) {
    if (!arguments.length) return big;
    big = value;
    return chart;
  };

  chart.small = function(value: any) {
    if (!arguments.length) return small;
    small = value;
    return chart;
  };

  return chart;
}

export function abbreviate(
  number: any,
  maxPlaces: any,
  forcePlaces: any,
  forceLetter: any
) {
  number = Number(number);
  forceLetter = forceLetter || false;
  if (forceLetter !== false) {
    return annotate(number, maxPlaces, forcePlaces, forceLetter);
  }
  var abbr;
  if (number >= 1e12) {
    abbr = "T";
  } else if (number >= 1e9) {
    abbr = "B";
  } else if (number >= 1e6) {
    abbr = "M";
  } else if (number >= 1e3) {
    abbr = "k";
  } else {
    abbr = "";
  }
  return annotate(number, maxPlaces, forcePlaces, abbr);
}

function annotate(number: any, maxPlaces: any, forcePlaces: any, abbr: any) {
  // set places to false to not round
  var rounded: any = 0;
  switch (abbr) {
    case "T":
      rounded = number / 1e12;
      break;
    case "B":
      rounded = number / 1e9;
      break;
    case "M":
      rounded = number / 1e6;
      break;
    case "k":
      rounded = number / 1e3;
      break;
    case "":
      rounded = number;
      break;
  }
  if (maxPlaces !== false) {
    var test = new RegExp("\\.\\d{" + (maxPlaces + 1) + ",}$");
    if (test.test("" + rounded)) {
      rounded = rounded.toFixed(maxPlaces);
    }
  }
  if (forcePlaces !== false) {
    rounded = Number(rounded).toFixed(forcePlaces);
  }
  rounded = rounded.toString().replace(/\./g, ",");
  return rounded + abbr;
}

export function canvas_html(
  d0: any,
  d1: any,
  item: any,
  i: any,
  id: any,
  links: any,
  hasLink?: any
) {
  if (!(window as any).viewUmsatzKunden) {
    (window as any).viewUmsatzKunden = function(client: any) {
      localStorage.setItem(
        "cur_umsatzdetails_kunden",
        JSON.stringify([client])
      );
      getRouter().replace("/kundenanalyse");
    };
  }
  if (!d0 || !d0[item]) {
    return;
  }
  var current = d0[item][1];
  var previous = 0;
  if (d1 && typeof d1[item] !== "undefined") {
    previous = d1[item][1];
  }
  if (typeof links === "undefined") {
    links = false;
  }

  var label =
    links && d0[item]
      ? '<a class="color-inherit" href="javascript:void(0);" onclick="viewUmsatzKunden(' +
        "'" +
        d0[item][0] +
        " " +
        d0[item][2] +
        "'" +
        ');">' +
        d0[item][2] +
        "</a>"
      : d0[item][2];

  return (
    '<div class="row d-flex align-items-center">' +
    '<div class="label col-xl-5 col-lg-5 col-md-5 d-flex align-items-center">' +
    '<span class="mr-2">' +
    (i < 10 ? "0" + i : i) +
    "</span>" +
    "<span>" +
    label +
    "</a></span>" +
    "</div>" +
    '<div class="col-xl-5 col-lg-5 col-md-5 d-flex align-items-center hb-wrapper">' +
    '<svg id="' +
    id +
    "-" +
    i +
    '" class="w-100"></svg>' +
    "</div>" +
    '<div class="val col-xl-2 col-lg-2 col-md-2 d-flex flex-column align-items-end">' +
    "<span>" +
    (current / 1000000 > 1
      ? getGermanNum((current / 1000000).toFixed(1)) + "M"
      : getGermanNum((current / 1000).toFixed(1)) + "k") +
    "</span>" +
    "<span>" +
    (previous / 1000000 > 1
      ? getGermanNum((previous / 1000000).toFixed(1)) + "M"
      : getGermanNum((previous / 1000).toFixed(1)) + "k") +
    "</span>" +
    "</div>" +
    "</div>"
  );
}

export function hideLieferantenCharts() {
  $("#lieferanten_top10").hide();
}

export function showLieferantenCharts() {
  $("#lieferanten_top10").show();
}

export function monthChart(
  data0: any,
  data1: any,
  colt1: string,
  colt2: string,
  id: string
) {
  if (!store.getters.selectedYear) {
    return;
  }
  var d0: any = [];
  var d1: any = [];
  for (var i = 1; i < 13; i++) {
    var key = store.getters.selectedYear[0] + "-" + (i < 10 ? "0" + i : i);
    var d0_data = 0;
    var d1_data = 0;
    if (data0[i] != undefined) {
      d0_data = data0[i];
    }
    if (data1[i] != undefined) {
      d1_data = data1[i];
    }
    d0[key] = d0_data;
    d1[key] = d1_data;
  }
  if (store.getters.rangeStep === "quarterly") {
    d0 = combineMonthToQuarter(d0);
    d1 = combineMonthToQuarter(d1);
  }

  chartVerticalGroupBar(d0, d1, colt1, colt2, id);
}

export function monthChartData(
  data0: any,
  data1: any,
  colt1: string,
  colt2: string,
  id: string
): any {
  if (!store.getters.selectedYear) {
    return;
  }
  var d0: any = [];
  var d1: any = [];
  for (var i = 1; i < 13; i++) {
    var key = store.getters.selectedYear[0] + "-" + (i < 10 ? "0" + i : i);
    var d0_data = 0;
    var d1_data = 0;
    if (data0[i] != undefined) {
      d0_data = data0[i];
    }
    if (data1[i] != undefined) {
      d1_data = data1[i];
    }
    d0[key] = d0_data;
    d1[key] = d1_data;
  }
  if (store.getters.rangeStep === "quarterly") {
    d0 = combineMonthToQuarter(d0);
    d1 = combineMonthToQuarter(d1);
  }

  return {
    d0: { ...d0 },
    d1: { ...d1 },
  };
}

export function oposBarCharts(d0r: any, d1r: any) {
  if (!d0r && !d1r) {
    return;
  }
  var d1 = d0r["mahnstufen"];
  var d0: any = [];
  if (d1) {
    d0 = [
      {
        title: "OPOS Gesamt",
        firstLabel: "OPOS Gesamt",
        secondLabel: "OPOS Gesamt - Zahlungsziel überschritten",
        firstVal: d1.sum,
        secondVal:
          d1["0_overdue"] + d1["1_overdue"] + d1["2_overdue"] + d1["3_overdue"],
      },
      {
        title: "OPOS Gesamt - Ohne Mahnstufe",
        firstLabel: "OPOS Mahnstufe",
        secondLabel: "OPOS Mahnstufe - Zahlungsziel überschritten",
        firstVal: d1["0_overdue"],
        secondVal: d1["0_underdue"],
      },
      {
        title: "OPOS Mahnstufe 1",
        firstLabel: "Mahnstufe 1",
        secondLabel: "Mahnstufe 1 - Zahlungsziel überschritten",
        firstVal: d1["1_overdue"],
        secondVal: d1["1_underdue"],
      },
      {
        title: "OPOS Mahnstufe 2",
        firstLabel: "Mahnstufe 2",
        secondLabel: "Mahnstufe 2 - Zahlungsziel überschritten",
        firstVal: d1["2_overdue"],
        secondVal: d1["2_underdue"],
      },
      {
        title: "OPOS Mahnstufe 3",
        firstLabel: "Mahnstufe 3",
        secondLabel: "Mahnstufe 3 - Zahlungsziel überschritten",
        firstVal: d1["3_overdue"],
        secondVal: d1["3_underdue"],
      },
    ];
  }
  var id = "chart_debitoren";
  var html = "";
  var i = 1;
  for (var item1 in d0) {
    html += opos_canvas_html(d0[item1], i, id);
    i++;
  }
  $("#" + id).html(html);
  i = 1;
  var maxVal = d0[0].firstVal;
  for (var item in d0) {
    chartHorizontalBar(
      [[0, d0[item].firstVal]],
      [[0, d0[item].secondVal]],
      id + "-" + i,
      maxVal
    );
    i++;
  }

  var d2 = d1r["mahnstufen"];
  var d3 = [
    {
      title: "OPOS Gesamt",
      firstLabel: "OPOS Gesamt",
      secondLabel: "OPOS Gesamt - Zahlungsziel überschritten",
      firstVal: d2.sum,
      secondVal:
        d2["0_overdue"] + d2["1_overdue"] + d2["2_overdue"] + d2["3_overdue"],
    },
  ];
  id = "chart_kreditoren";
  html =
    '<div class="row align-items-center">' +
    '<div class="label col-md-7 col-sm-12">' +
    '<p class="m-0">' +
    d3[0].firstLabel +
    "</p>" +
    '<p class="m-0">' +
    d3[0].secondLabel +
    "</p>" +
    "</div>" +
    '<div class="col-md-4 col-10 d-flex align-items-center pr-3 hb-wrapper">' +
    '<svg id="' +
    id +
    "-" +
    1 +
    '" class="w-100"></svg>' +
    "</div>" +
    '<div class="val col-md-1 col-2 d-flex flex-column align-items-end">' +
    "<span>" +
    (d3[0].firstVal / 1000000 > 1
      ? getGermanNum((d3[0].firstVal / 1000000).toFixed(1)) + "M"
      : getGermanNum((d3[0].firstVal / 1000).toFixed(1)) + "k") +
    "</span>" +
    "<span>" +
    (d3[0].secondVal / 1000000 > 1
      ? getGermanNum((d3[0].secondVal / 1000000).toFixed(1)) + "M"
      : getGermanNum((d3[0].secondVal / 1000).toFixed(1)) + "k") +
    "</span>" +
    "</div>" +
    "</div>";
  $("#" + id).html(html);
  chartHorizontalBar(
    [[0, d3[0].firstVal]],
    [[0, d3[0].secondVal]],
    id + "-" + 1,
    d2.sum
  );

  var opData = [
    d0[0].firstVal,
    d3[0].firstVal,
    d0[0].firstVal - d3[0].firstVal,
  ];
  var opLabel = ["Debitoren", "Kreditoren", "Gesamt"];
  var colors = [
    "var(--primary-dark)",
    "var(--primary-light)",
    "var(--primary)",
  ];
  chartVerticalSingleBar(
    opData,
    opLabel,
    null,
    "chart_opos",
    true,
    true,
    colors,
    null
  );
}

export function opos_canvas_html(d: any, i: any, id: any) {
  var current = d.firstVal;
  var previous = d.secondVal;
  return (
    '<div class="d-flex">' +
    '<span class="mr-3 hor-num font-weight-bold">' +
    (i < 10 ? "0" + i : i) +
    "</span>" +
    '<div class="w-100">' +
    '<p class="m-0 font-weight-bold">' +
    d.title +
    "</p>" +
    '<div class="row align-items-center">' +
    '<div class="label col-md-5 col-sm-12">' +
    '<p class="m-0">' +
    d.firstLabel +
    "</p>" +
    '<p class="m-0">' +
    d.secondLabel +
    "</p>" +
    "</div>" +
    '<div class="col-md-5 col-10 d-flex align-items-center hb-wrapper">' +
    '<svg id="' +
    id +
    "-" +
    i +
    '" class="w-100"></svg>' +
    "</div>" +
    '<div class="val col-md-2 col-2 d-flex flex-column align-items-end">' +
    "<span>" +
    (current / 1000000 > 1
      ? getGermanNum((current / 1000000).toFixed(1)) + "M"
      : getGermanNum((current / 1000).toFixed(1)) + "k") +
    "</span>" +
    "<span>" +
    (previous / 1000000 > 1
      ? getGermanNum((previous / 1000000).toFixed(1)) + "M"
      : getGermanNum((previous / 1000).toFixed(1)) + "k") +
    "</span>" +
    "</div>" +
    "</div>" +
    "</div>" +
    "</div>"
  );
}

export function chartLiquiditatsstatus(d: any, limit: any) {
  if (!d) {
    return;
  }
  limit = parseInt(limit);
  var debData = d["deb"].slice(0, limit),
    kredData = d["kred"].slice(0, limit);
  var opData = [];
  var opLabel = ["1", "2", "3"];
  var colors = [
    "#var(--primary-dark)",
    "var(--primary-light)",
    "var(--primary)",
  ];
  var d1 = [],
    sumDeb = 0,
    sumKred = 0,
    sumTotal = 0;

  for (var i = 0; i < debData.length; i++) {
    d1.push(debData[i] - kredData[i]);
    sumDeb += debData[i];
    sumKred += kredData[i];
  }
  sumTotal = sumDeb - sumKred;
  opData = [sumDeb, sumKred, sumTotal];
  chartVerticalSingleBar(
    opData,
    opLabel,
    null,
    "chart_liquiditatsstatus_bar",
    true,
    true,
    colors,
    true
  );
  chartArea(
    debData,
    "chart_area_deb",
    "#var(--primary-dark)",
    debData,
    kredData,
    d1
  );
  chartArea(
    kredData,
    "chart_area_kred",
    "var(--primary-light)",
    debData,
    kredData,
    d1
  );
  chartArea(d1, "chart_area_diff", "#6fcf97", debData, kredData, d1);
}

export function chartArea(
  d: any,
  id: any,
  color: any,
  debD: any,
  kredD: any,
  diffD: any
) {
  if (!d3.selectAll("#" + id).node()) {
    return;
  }
  var data = d;
  d3.selectAll("#" + id + " svg").remove();
  var svg = d3
    .selectAll("#" + id)
    .append("svg")
    .attr("class", "area-chart")
    .attr(
      "width",
      d3
        .selectAll("#" + id)
        .node()
        .getBoundingClientRect().width
    )
    .attr(
      "height",
      d3
        .selectAll("#" + id)
        .node()
        .getBoundingClientRect().height
    );
  var margin = { top: 40, right: 20, bottom: 40, left: 45 };
  var width =
    svg.node().getBoundingClientRect().width - margin.left - margin.right;
  var height =
    svg.node().getBoundingClientRect().height - margin.top - margin.bottom;
  var g = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
  var x = d3
    .scaleLinear()
    .rangeRound([0, width - 15])
    .domain([0, d.length - 1]);
  var y = d3
    .scaleLinear()
    .rangeRound([height, 0])
    .domain([d3.min(d), d3.max(d)]);
  let dates: any[] = [];
  const today = new Date().getTime();
  for (let i = 0; i < d.length; i++) {
    const date = (
      "0" + new Date(today + 24 * 60 * 60 * 1000 * i).getDate()
    ).slice(-2);
    const month = (
      "0" +
      (new Date(today + 24 * 60 * 60 * 1000 * i).getMonth() + 1)
    ).slice(-2);
    const year = new Date(today + 24 * 60 * 60 * 1000 * i).getFullYear();
    dates.push(date + "." + month + "." + year);
  }
  svg
    .append("defs")
    .append("linearGradient")
    .attr("id", id + "gradient_area")
    .attr("x1", "0%")
    .attr("x2", "0%")
    .attr("y1", "0%")
    .attr("y2", "100%")
    .selectAll("stop")
    .data([
      { offset: "0%", color: color, opacity: 0.7 },
      { offset: "100%", color: color, opacity: 0 },
    ])
    .enter()
    .append("stop")
    .attr("offset", function(d: any) {
      return d.offset;
    })
    .attr("stop-color", function(d: any) {
      return d.color;
    })
    .attr("stop-opacity", function(d: any) {
      return d.opacity;
    });
  svg
    .append("defs")
    .append("linearGradient")
    .attr("id", id + "gradient_line")
    .attr("gradientUnits", "userSpaceOnUse")
    .attr("x1", "0%")
    .attr("x2", "0%")
    .attr("y1", "0%")
    .attr("y2", function() {
      return d3.min(data) >= 0 ? -10 : y(0) < 0 ? 0 : y(0);
    })
    .selectAll("stop")
    .data([
      { offset: "100%", color: color },
      { offset: "100%", color: "#e76868" },
    ])
    .enter()
    .append("stop")
    .attr("offset", function(d: any) {
      return d.offset;
    })
    .attr("stop-color", function(d: any) {
      return d.color;
    });
  g.append("g")
    .attr("class", "axis axis-y")
    .attr("transform", "translate(-15, 0)")
    .call(
      d3
        .axisLeft(y)
        .ticks(4, "s")
        .tickSize(-width - 15)
    );
  var area = d3
    .area()
    .curve(d3.curveMonotoneX)
    .x(function(d: any, i: any) {
      return x(i);
    })
    .y0(height)
    .y1(function(d: any, i: any) {
      return y(d);
    });
  var line = d3
    .line()
    .curve(d3.curveMonotoneX)
    .x(function(d: any, i: any) {
      return x(i);
    })
    .y(function(d: any) {
      return y(d);
    });
  g.append("path")
    .data([d])
    .attr("d", area)
    .attr("fill", "url(#" + id + "gradient_area)");
  g.append("path")
    .data([d])
    .attr("d", line)
    .attr("fill", "none")
    .attr("stroke", "url(#" + id + "gradient_line)")
    .attr("stroke-width", 3);
  var focus = g
    .append("g")
    .attr("class", "focus")
    .style("display", "none");
  focus
    .append("text")
    .attr("class", "value")
    .attr("y", -15)
    .attr("dy", ".35em")
    .attr("text-anchor", "middle")
    .style("font-size", 12);
  focus
    .append("text")
    .attr("class", "date")
    .attr("y", -30)
    .attr("dy", ".35em")
    .attr("text-anchor", "middle")
    .style("font-size", 12);
  focus
    .append("line")
    .attr("stroke", "white")
    .attr("stroke-width", 2);
  focus
    .append("circle")
    .attr("r", 6)
    .attr("fill", color)
    .attr("stroke", "white")
    .attr("stroke-width", 3);
  var rect: any = g
    .append("rect")
    .attr("width", width)
    .attr("height", height)
    .attr("fill", "none")
    .attr("pointer-events", "all")
    .on("mouseover", function() {
      d3.selectAll(".focus").style("display", null);
    })
    .on("mouseout", function() {
      d3.selectAll(".focus").style("display", "none");
    })
    .on("mousemove", () => mousemove(rect));

  const xAxis = g.append("g").attr("class", "axis axis-x");
  dates.forEach((d, i) => {
    if (
      dates.length === 7 ||
      (dates.length === 30 && i % 2 === 0) ||
      (dates.length === 90 && i % 5 === 0)
    ) {
      xAxis
        .append("g")
        .attr("transform", `translate(${x(i)}, ${height + 20})`)
        .append("text")
        .text(d.substr(0, 6))
        .attr("fill", "var(--primary-dark)")
        // .attr('transform', `rotate(${dates.length > 7 ? -10 : 0})`)
        .attr("text-anchor", "middle");
    }
  });

  var bisectDate = d3.bisector(function(d: any) {
    return d;
  }).left;

  var yDeb: any;
  var yKred: any;
  var yDiff: any;
  if (debD) {
    yDeb = d3
      .scaleLinear()
      .rangeRound([height, 0])
      .domain([d3.min(debD), d3.max(debD)]);
  }
  if (kredD) {
    yKred = d3
      .scaleLinear()
      .rangeRound([height, 0])
      .domain([d3.min(kredD), d3.max(kredD)]);
  }
  if (diffD) {
    yDiff = d3
      .scaleLinear()
      .rangeRound([height, 0])
      .domain([d3.min(diffD), d3.max(diffD)]);
  }

  function mousemove(rect: any) {
    var xData = [];
    for (var j = 0; j < data.length; j++) {
      xData.push(j);
    }
    var x0 = x.invert(d3.mouse(rect._groups[0][0])[0]),
      i = bisectDate(xData, x0, 1),
      dx = x0 - i > i - x0 ? i : i - 1,
      dy = x0 - i > i - x0 ? data[i] : data[i - 1];
    var dyDeb = x0 - i > i - x0 ? debD[i] : debD[i - 1];
    var dyKred = x0 - i > i - x0 ? kredD[i] : kredD[i - 1];
    var dyDiff = x0 - i > i - x0 ? diffD[i] : diffD[i - 1];
    if (yDeb(dyDeb) || yDeb(dyDeb) === 0) {
      d3.selectAll("#chart_area_deb .focus").attr(
        "transform",
        "translate(" + x(dx) + "," + yDeb(dyDeb) + ")"
      );
      d3.selectAll("#chart_area_deb .focus")
        .select(".date")
        .text(dates[i - 1]);
      d3.selectAll("#chart_area_deb .focus")
        .select(".value")
        .text(
          dyDeb / 1000000 > 1
            ? getGermanNum((dyDeb / 1000000).toFixed(1)) + "M"
            : getGermanNum((dyDeb / 1000).toFixed(1)) + "k"
        );
      d3.selectAll("#chart_area_deb .focus")
        .select("line")
        .attr("x0", 0)
        .attr("x1", 0)
        .attr("y0", yDeb(dyDeb))
        .attr("y1", height);
    }
    if (yKred(dyKred) || yKred(dyKred) === 0) {
      d3.selectAll("#chart_area_kred .focus").attr(
        "transform",
        "translate(" + x(dx) + "," + yKred(dyKred) + ")"
      );
      d3.selectAll("#chart_area_kred .focus")
        .select(".date")
        .text(dates[i - 1]);
      d3.selectAll("#chart_area_kred .focus")
        .select(".value")
        .text(
          dyKred / 1000000 > 1
            ? getGermanNum((dyKred / 1000000).toFixed(1)) + "M"
            : getGermanNum((dyKred / 1000).toFixed(1)) + "k"
        );
      d3.selectAll("#chart_area_kred .focus")
        .select("line")
        .attr("x0", 0)
        .attr("x1", 0)
        .attr("y0", yKred(dyKred))
        .attr("y1", height);
    }
    if (yDiff(dyDiff) || yDiff(dyDiff) === 0) {
      d3.selectAll("#chart_area_diff .focus").attr(
        "transform",
        "translate(" + x(dx) + "," + yDiff(dyDiff) + ")"
      );
      d3.selectAll("#chart_area_diff .focus")
        .select(".date")
        .text(dates[i - 1]);
      d3.selectAll("#chart_area_diff .focus")
        .select(".value")
        .text(
          dyDiff / 1000000 > 1
            ? getGermanNum((dyDiff / 1000000).toFixed(1)) + "M"
            : getGermanNum((dyDiff / 1000).toFixed(1)) + "k"
        );
      d3.selectAll("#chart_area_diff .focus")
        .select("line")
        .attr("x0", 0)
        .attr("x1", 0)
        .attr("y0", yDiff(dyDiff))
        .attr("y1", height);
    }
  }
}
